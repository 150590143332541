import React from 'react';
import './assets/css/Features.css';
import feature1 from './assets/images/image_feature01.png';
import feature2 from './assets/images/image_feature02.png';
import feature3 from './assets/images/image_feature03.png';
import feature4 from './assets/images/image_feature04.png';
import feature5 from './assets/images/image_feature05.png';

function SecondPage() {
  return (
    <section className="feature-page">
      <h2 className="main-title">Main Features</h2>
      <div className="features-container">
        <div className="feature-box">
          <img src={feature1} alt="Feature 1" />
          <h3>A Brand New Point-of-View Live App </h3>
          <p>Share your live surroundings immersively from a POV perspective, as well as 3rd party digital contents from other apps </p>
        </div>
        <div className="feature-box">
          <img src={feature2} alt="Feature 2" />
          <h3>Public rooms & Private rooms</h3>
          <p>Ability to open Public rooms for everyone to join as well as Private rooms you can share with friends and family</p>
        </div>
        <div className="feature-box">
          <img src={feature3} alt="Feature 3" />
          <h3>Hosting</h3>
          <p>Room hosts can grant view-sharing permissions to members</p>
        </div>
        <div className="feature-box">
          <img src={feature4} alt="Feature 4" />
          <h3>Multiple Screen View</h3>
          <p>Enjoy a multi-view of different experience or of a same experience, shared by the room members</p>
        </div>
        <div className="feature-box">
          <img src={feature5} alt="Feature 5" />
          <h3>Recording</h3>
          <p>Record your shared screen to save & download</p>
        </div>
      </div>
    </section>
  );
}

export default SecondPage;


// import React, { useEffect, useRef, useState } from 'react';
// import './SecondPage.css';

// function SecondPage() {
//   const [isVisible, setIsVisible] = useState(false);
//   const secondPageRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         } else {
//           setIsVisible(false);
//         }
//       },
//       { threshold: 0.15 } // 15% 기준으로 페이지 상태를 감지
//     );

//     if (secondPageRef.current) {
//       observer.observe(secondPageRef.current);
//     }

//     return () => {
//       if (secondPageRef.current) {
//         observer.unobserve(secondPageRef.current);
//       }
//     };
//   }, []);

//   return (
//     <section
//       className={`second-page ${isVisible ? 'visible' : 'hidden'}`}
//       ref={secondPageRef}
//     >
//       <div className="overlay">
//         <div className="content">
//           <h1>Discover the Future of Immersive Chatting</h1>
//           <h2>on Apple Vision Pro</h2>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default SecondPage;
