import React, { useState, useEffect } from 'react';
import './assets/css/LastPage.css';

function LastPage({ userCheckInfo, email, setEmail, submitTime  }) {
  const [message, setMessage] = useState({ text: '', type: '', hidden: true }); // 메시지 상태 초기화

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage({ text: 'Please enter an email address.', type: 'warning', hidden: false });
      return;
    }

    try {
      const response = await fetch('/api/update_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          submit_time: submitTime, // App.js에서 전달된 SubmitTime
        }),
      });

    // try {
    //   const response = await fetch('/api/signup', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ 
    //       email,
    //       headset: userCheckInfo,
    //      }),
    //   });

      const data = await response.json();
      if (data.success) {
        setMessage({ text: 'Thanks for your submission!', type: 'success', hidden: false });
      } else if (data.error) {
        setMessage({ text: "This email has already been registered, please check it again.", type: 'error', hidden: false }); // 중복된 이메일 경고
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ text: 'An error occurred. Please try again.', type: 'error', hidden: false });
    }
  };

// 메시지가 있을 때 5초 후에 자동으로 숨기도록 설정
useEffect(() => {
  if (!message.hidden) {
    const timer = setTimeout(() => {
      setMessage((prevMessage) => ({
        ...prevMessage,
        hidden: true // 메시지를 숨김 상태로 설정
      }));
    }, 3000); // 3초 후에 메시지 숨김
    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 제거
  }
}, [message.hidden]);


  return (
    <div className="last-page">
      <div className="overlay-last">
        <div className="sub-text-last">
          <p>Please enter your Email to receive Promo Code along with more detailed information on the app!</p>
        </div>
        <form className="cta-form-last" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email} // App.js에서 전달된 이메일 사용
            onChange={(e) => setEmail(e.target.value)} // 이메일 값 변경 시 App.js로 전달
          />
          <button type="submit">Sign Up</button>
        </form>

        {/* 메시지 표시 부분 */}
        <div
          className={`message ${message.type}`}
          style={{ visibility: message.hidden ? 'hidden' : 'visible' }} // 메시지 숨기기
        >
          {message.text}
        </div>
      </div>
    </div>
  );
}

export default LastPage;
