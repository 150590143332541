import React from 'react';
import './assets/css/Footer.css';
import logo from './assets/images/image_logo.png';  

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          {/* Add CI or Logo*/}
          <img src={logo} alt="XR Corp Logo"/>
          {/* <p>LivePOV</p> */}
        </div>
        <div className="footer-center">
          <p>LEGAL</p>
          <div className="footer-section">
            <p><a href="#!">Terms & Conditions</a></p>
            <p><a href="#!">Privacy Policy</a></p>
          </div>
        </div>
        <div className="footer-right">
          <p>CONTACT</p>
          <div className='footer-section-right'>
            <p><a href="mailto:xrgoat777@gmail.com" target="_self">xrgoat777@gmail.com</a></p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 by xrkrcorp.</p>
      </div>
    </footer>
  );
}

export default Footer;
