import React from 'react';
import './assets/css/LeftImageRightText.css';
import monitoringImage from './assets/images/image-monitoring.png'; // 이미지 파일 경로

function ThirdPage() {
  return (
    <div className="left-image-right-text-page">
      {/* 이미지 영역 */}
      <div className="image-section-left">
        <img src={monitoringImage} alt="Monitoring"/>
      </div>
      
      {/* 텍스트 영역 */}
      <div className="text-section-right">
        <h1>Real-time Monitoring</h1>
        <p>
          Could be used as a monitoring tool for law enforcements or security workforce to prevent & predict potential unlawful activities
        </p>
      </div>
    </div>
  );
}

export default ThirdPage;



// import React from 'react';
// import './ThirdPage.css';
// import videoGif from './xr-web-video.gif'; // 이미지 파일 import

// function ThirdPage() {
//   return (
//     <section className="third-page">
//       <div className="content">
//         {/* 왼쪽: 이미지 */}
//         <div className="media-container">
//           <img src={videoGif} alt="XR Web Experience" />
//         </div>

//         {/* 오른쪽: 텍스트 박스 */}
//         <div className="text-container">
//           <h2>UNPARALLELED TRACKING</h2>
//           <p>&lt;10mm &nbsp;&lt;1 deg</p>
//           <p>Positioning Accuracy</p>
//           <h3>100Hz</h3>
//           <p>Refresh Rate</p>
//           <p>Powered by Next-Gen Computer Vision and SLAM Technology.</p>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default ThirdPage;
