import React, { useState, useEffect } from 'react';
import './App.css';
import UserCheck from './components/UserCheck';
import FirstPage from './components/FirstPage';
import SecondPage from './components/SecondPage';
import ThirdPage from './components/ThirdPage';
import FourthPage from './components/FourthPage';
import FifthPage from './components/FifthPage';
import SixthPage from './components/SixthPage';
import SeventhPage from './components/SeventhPage';
import EighthPage from './components/EighthPage';
import LastPage from './components/LastPage';
import Footer from './components/Footer';
import HeaderBar from './components/HeaderBar';

function App() {
  const [userCheckInfo, setUserCheckInfo] = useState(null); // UserCheck 정보 상태 관리
  const [email, setEmail] = useState(''); // 이메일 상태 관리
  const [submitTime, setSubmitTime] = useState(''); // SubmitTime 상태 관리


  const handleUserCheckComplete = (selectedOption, submitTime) => {
    setUserCheckInfo(selectedOption);
    setSubmitTime(submitTime); // 서버로부터 받은 SubmitTime을 상태로 저장
  };

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
  });

  return (
    <div className="App">
      <HeaderBar />
      
      {!userCheckInfo ? (
        <UserCheck onComplete={handleUserCheckComplete} />
      ) : (
        <>
          <FirstPage userCheckInfo={userCheckInfo} email={email} setEmail={setEmail} submitTime={submitTime} />
          <SecondPage />
          <SixthPage />
          <SeventhPage />
          <FifthPage />
          <EighthPage />
          <FourthPage />
          <ThirdPage />
          <LastPage userCheckInfo={userCheckInfo} email={email} setEmail={setEmail} submitTime={submitTime}  />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
