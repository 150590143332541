import React from 'react';
import './assets/css/LeftImageRightText.css';
import imageSrc from './assets/images/image_room.png'; // 이미지 파일 경로

function SixthPage() {
  return (
    <div className="left-image-right-text-page">
      {/* 이미지 영역 */}
      <div className="image-section-left">
        <img src={imageSrc} alt="POV Live – Public Room " />
      </div>
      
      {/* 텍스트 영역 */}
      <div className="text-section-right">
        <h1>POV Live – Public Room </h1>
        <p>
        Enjoy real-time immersive contents in Public Rooms when people broadcast variety of events 
        such as sports games, artist performances, international trips, etc.
        </p>
      </div>
    </div>
  );
}

export default SixthPage;