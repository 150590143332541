import React, { useState } from 'react';
import './assets/css/UserCheck.css';
import backgroundImage from './assets/images/image_main.png'; // 이미지 파일 경로 수정

function UserCheck({ onComplete }) {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOption) {
      alert('Please select an option.');
      return;
    }

    // UserCheck 정보를 App.js에 전달하여 상태에 저장하고, 다음 페이지로 넘어감
    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_check: selectedOption
        }),
      });

      const data = await response.json();
      if (response.ok) {
        onComplete(selectedOption, data.SubmitTime); // 서버로부터 받은 SubmitTime을 App으로 전달
      } else {
        alert('Failed to save data.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="AppQuestion">
      <div className="user-check" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="black-box-question">
          <div>
            <div className="content-question">
              <h1 className="question">What kind of headset do you have?</h1>
              <form className="radio-form" onSubmit={handleSubmit}>
                <div className="radio-option">
                  <input
                    type="radio"
                    id="apple"
                    name="headset"
                    value="Apple Vision Pro"
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="apple">Apple Vision Pro</label>
                </div>
                <div className="radio-option">
                  <input
                    type="radio"
                    id="meta"
                    name="headset"
                    value="Meta Quest"
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="meta">Meta Quest</label>
                </div>
                <div className="radio-option">
                  <input
                    type="radio"
                    id="both"
                    name="headset"
                    value="Both"
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="both">Both</label>
                </div>
                <div className="radio-option">
                  <input
                    type="radio"
                    id="none"
                    name="headset"
                    value="None"
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="none">None</label>
                </div>
                <button type="submit" className="send-button-question">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCheck;
