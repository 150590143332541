import React from 'react';
import './assets/css/LeftImageRightText.css';
import imageSrc from './assets/images/image_hands.png'; // 이미지 파일 경로

function FifthPage() {
  return (
    <div className="left-image-right-text-page">
      {/* 이미지 영역 */}
      <div className="image-section-left">
        <img src={imageSrc} alt="Hands-free POV Live" />
      </div>
      
      {/* 텍스트 영역 */}
      <div className="text-section-right">
        <h1>Hands-free POV Live</h1>
        <p>
          Share your situations with others or join others' environment immersively, 
          when both hands are tied up. Examples include cooking, working out, etc.
        </p>
      </div>
    </div>
  );
}

export default FifthPage;


// import React, { useState, useEffect } from 'react';
// import './FifthPage.css';
// import image1 from './image1.png'; // 이미지 파일 import
// import image2 from './image2.png';
// import image3 from './image3.png';
// import image4 from './image4.png';

// function FifthPage() {
//   const images = [image1, image2, image3, image4];
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [isPaused, setIsPaused] = useState(false);

//   const prevImageIndex =
//     currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;
//   const nextImageIndex =
//     currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;

//   useEffect(() => {
//     if (!isPaused) {
//       const intervalId = setInterval(() => {
//         setCurrentImageIndex((prevIndex) =>
//           prevIndex === images.length - 1 ? 0 : prevIndex + 1
//         );
//       }, 5000); // 5초마다 자동으로 다음 이미지로 넘어감

//       return () => clearInterval(intervalId);
//     }
//   }, [isPaused, currentImageIndex]);

//   const handleMouseEnter = () => {
//     setIsPaused(true); // 마우스 오버 시 애니메이션 일시 중지
//   };

//   const handleMouseLeave = () => {
//     setIsPaused(false); // 마우스가 나가면 애니메이션이 재개
//   };

//   return (
//     <section className="fifth-page">
//       <div className="text-container">
//         <h2>
//           Seamless <strong>Compatibility</strong> with Thousands of <br />
//           SteamVR Games
//         </h2>
//       </div>

//       <div
//         className="slider-container"
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <div
//           className="image-wrapper"
//           style={{
//             transform: `translateX(${-currentImageIndex * 100}%)`,
//           }}
//         >
//           {images.concat(images).map((image, index) => (
//             <img
//               key={index}
//               src={image}
//               alt={`Slide ${index + 1}`}
//               className="image"
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// }

// export default FifthPage;
