import React from 'react';
import './assets/css/HeaderBar.css';
import logo from './assets/images/image_logo.png';  

function HeaderBar() {
  return (
    <div className="header-bar">
      <img src={logo} alt="XR Corp Logo" className="header-logo" />
    </div>
  );
}

export default HeaderBar;
