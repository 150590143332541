// import React from 'react';
// import './assets/css/DualImageOverText.css';
// import image1 from './assets/images/image_health01.png';
// import image2 from './assets/images/image_health02.png';

// function FourthPage() {
//   return (
//     <div className="fourth-page">
//       <div className="image-container-fourth">
//         <img src={image1} alt="Healthcare Monitoring 1" className="image-fourth" />
//         <img src={image2} alt="Healthcare Monitoring 2" className="image-fourth" />
//       </div>
//       <div className="text-overlay-fourth">
//         <h1 className="title-fourth">Healthcare</h1>
//         <p className="description-fourth">Can be used for various protective purposes – i.e. Monitoring Alzheimer patient’s POV 24/7 to ensure safety of the patient</p>
//       </div>
//     </div>
//   );
// }

// export default FourthPage;

import React from 'react';
import './assets/css/DualImageOverText.css';
import image1 from './assets/images/image_health01.png';
import image2 from './assets/images/image_health02.png';

function FourthPage() {
  return (
    <div className="dual-image-over-text-page">
      <div className="image-container-dual">
        <img src={image1} alt="Healthcare Monitoring 1" className="image-dual" />
        <img src={image2} alt="Healthcare Monitoring 2" className="image-dual" />
      </div>
      <div className="text-section-over">
        <h1>Healthcare</h1>
        <p>Can be used for various protective purposes – i.e. Monitoring Alzheimer patient’s POV 24/7 to ensure safety of the patient</p>
      </div>
    </div>
  );
}

export default FourthPage;
