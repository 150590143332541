import React from 'react';
import './assets/css/DualImageOverText.css';
import image1 from './assets/images/image_privateroom01.png';
import image2 from './assets/images/image_privateroom02.png';

function SeventhPage() {
  return (
    <div className="dual-image-over-text-page">
      <div className="image-container-dual">
        <img src={image1} alt="Private Room 1" className="image-dual" />
        <img src={image2} alt="Private Room 2" className="image-dual" />
      </div>
      <div className="text-section-over">
        <h1>POV Live – Private Room</h1>
        <p>Share moments with your loved ones in private room for on-site experience</p>
      </div>
    </div>
  );
}

export default SeventhPage;
