import React from 'react';
import './assets/css/DualImageOverText.css';
import image1 from './assets/images/image_businesswork01.png';
import image2 from './assets/images/image_businesswork02.png';

function EighthPage() {
  return (
    <div className="dual-image-over-text-page">
      <div className="image-container-dual">
        <img src={image1} alt="Remote Business Work 1" className="image-dual" />
        <img src={image2} alt="Remote Business Work 2" className="image-dual" />
      </div>
      <div className="text-section-over">
        <h1>Remote Business Work</h1>
        <p>Handle issues efficiently with a realistic on-site experience
            without having to physically visit the site
        </p>
      </div>
    </div>
  );
}

export default EighthPage;
